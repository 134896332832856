import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";

import { mediaPhone, mediaDesktop } from "../../styles/Breakpoints";
import { determineStep } from "../../../utils/determineStep";

const getPercentage = (stepsV3Flow, currentPath) => {
  let currentStep = determineStep(stepsV3Flow, currentPath, true);
  let percentage;

  switch (currentStep) {
    default:
    case 0:
      percentage = 25;
      break;
    case 1:
      percentage = 50;
      break;
    case 2:
      percentage = 75;
      break;
    case 3:
      percentage = 100;
      break;
  }
  return `${percentage}%`;
};

const Bar = styled.div`
  ${mediaPhone} {
    position: relative;
    height: 2px;
    top: 7px;
    background-color: ${({ theme }) => theme.progress_bar};
  }

  ${mediaDesktop} {
    position: absolute;
    width: 4px;
    background-color: ${({ theme }) => theme.progress_bar};
    z-index: 1;
  }
`;

const BarBackground = styled.div`
  opacity: 0.2;
  ${mediaPhone} {
    position: relative;
    height: 2px;
    top: 8px;
    background-color: white;
  }

  ${mediaDesktop} {
    position: absolute;
    width: 4px;
    background-color: white;
    z-index: 1;
  }
`;

const StepDesktopContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const BaseFlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const StepDesktopNumberWrapper = styled(BaseFlexColumn)`
  width: 2rem;

  p {
    margin-top: 4px;
    color: #ffffff;
  }
`;

const StepTextWrapper = styled(BaseFlexColumn)`
  flex: 1;

  h4 {
    color: white;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.2;
    margin: 0px;
  }

  p {
    color: white;
    font-size: 12px;
    margin: 0px;
  }
`;

const Steps = {
  Bar: ({ config, currentPath }) => {
    if (config.stepsVersion != 3) return null;

    return (
      <React.Fragment>
        <BarBackground
          style={{
            height: "100%",
          }}
        />
        <Bar
          style={{
            height: `${getPercentage(config.stepsV3Flow, currentPath)}`,
          }}
        />
      </React.Fragment>
    );
  },
  BarMobile: ({ config, currentPath }) => {
    if (config.stepsVersion != 3) return null;

    return (
      <React.Fragment>
        <BarBackground
          style={{
            width: "100%",
          }}
        />
        <Bar
          style={{
            width: `${getPercentage(config.stepsV3Flow, currentPath)}`,
          }}
        />
      </React.Fragment>
    );
  },
  Description: ({ config, currentPath }) => {
    if (config.stepsVersion != 3) return null;

    const currentStep = determineStep(config.stepsV3Flow, currentPath, true);
    const isActive = (step) => {
      return step === currentStep;
    };

    return (
      <>
        {config.stepsV3Flow.map((_, step) => {
          return (
            <StepDesktopContainer key={step}>
              <StepDesktopNumberWrapper active={`${isActive(step)}`}>
                <p style={{ opacity: `${isActive(step) ? "1" : "0.5"}` }}>{`${
                  step + 1
                }.`}</p>
              </StepDesktopNumberWrapper>
              <StepTextWrapper>
                <h4 style={{ opacity: `${isActive(step) ? "1" : "0.5"}` }}>
                  {config.stepsV3Text[step][0]}
                </h4>
                <p style={{ opacity: `${isActive(step) ? "0.8" : "0.5"}` }}>
                  {config.stepsV3Text[step][1]}
                </p>
              </StepTextWrapper>
            </StepDesktopContainer>
          );
        })}
      </>
    );
  },
};

Steps.propTypes = {
  config: PropTypes.object.isRequired,
  currentPath: PropTypes.string.isRequired,
};

export default Steps;
