import { configBuilder } from "ccui-framework";
import { commonConfig } from "../../../src/config/partner";

const Config = {
  mastercard: {
    ...commonConfig,
    reduxLogger: false,

    //layout
    layoutVersion: 3,
    //layout bottom images for layout v3

    exchangeMastTextPartOneV1: "Trade Gift Cards for a",
    exchangeMastTextPartTwoV1: "Virtual Prepaid Mastercard®",

    soundGoodAddAnotherCardLinkTxt: "+ Add Another Card",
    monthYearTextPlaceHolder: "MM/YY",
    cvvTextPlaceHolder: "CVV",
    orderCompleteCardInfoTxt: {
      text: (config) =>
        `If your trade order is accepted, your Virtual Prepaid Mastercard will be emailed to you. This process may take up to one business day.`,
    },

    // headerBackTo: true,
    headerLogoOrder: ["headerLogoBrand", "headerLogoCC"],
    headerLogoBrandIcon:
      "https://cdn.cardcash.com/website/partners/mastercard/mastercard_logo.png",
    tloMissingNumberLink: "Missing Number? Learn why",
    identityTloTitle: "Choose your phone number",
    identityTloPassCodeTitle: "Enter the code ",
    identityTloErrorTitle: "Phone Number Information",
    identityTloExpiredTitle: "Expired",

    tloVerifyBtnTxt: "Continue",

    tloErrorTxt: `<p>The phone numbers we list are pulled from trusted third parties who provide numbers associated with your identity.
    We can not accept any other phone numbers for verification.</p>  <p> Security is of paramount importance to CardCash and we apologize if you are unable to continue. </p>`,

    // Verification
    verificationType: "tlo",
    // meta
    paymentType: "Gift Card",
    aOrAn: "a",
    partnerBrand: "Mastercard",
    partnerURL: "http://www.mastercard.com/",
    partnerEmail: "support@cardcash.com",
    giftCardTerms:
      "https://www.mastercard.us/en-us/vision/who-we-are/terms-of-use.html",
    termsAndConditionsBuilder: (config) =>
      `Restrictions apply, see <a href='${config.giftCardTerms}'>Prepaid Mastercard Terms & Conditions</a>`,

    cardDetailsTitle: "Provide Gift Card Details",
    identityCardInfoTitle: "Enter your credit card info",
    identityBillingInfoTitle: "Enter your billing info",

    // social
    facebookURL: "https://www.facebook.com/CardCash/",
    twitterURL: "https://twitter.com/CardCash",
    youTubeURL: null,
    instagramURL: "https://www.instagram.com/cardcash",
    googleURL: null,
    linkedInURL: null,
    pinterestURL: "https://www.pinterest.com/cardcash/",

    // api
    googleAnalytics: "",
    x_cc_app: "Qmb30L2im",
    baseURL: "https://production-api.cardcash.com/",

    // header
    headerMenuItemOrder: ["contactItem", "faqItem"],
    headerMenuFaqItem: {
      text: (config) => "FAQ",
    },

    soundsGoodSaveButtonTxt: "Get Offer",
    // steps
    stepsVersion: 3,
    stepsV3Text: [
      [
        "Get Offer",
        "Tell us about the brand and balance and get an instant offer.",
      ],
      ["Provide Details", "Enter the details for your gift card."],
      [
        "Verify Your Identity",
        "We’ll use this information to decide if we can accept your card.",
      ],
      [
        "Receive your Virtual Prepaid Mastercard",
        "Your Virtual Prepaid Mastercard will be emailed to you within 1 business day.",
      ],
    ],
    customDisclosure: `<p>Use your virtual prepaid card online everywhere Mastercard is accepted in the U.S. Issued by The Bancorp Bank, Member FDIC, pursuant to license by Mastercard International Incorporated. Mastercard is a registered trademark, and the circles design is a trademark of Mastercard International Incorporated. Your use of the prepaid card is governed by the Cardholder Agreement, and some fees may apply. This is not a gift card. Please note that prepaid cards are subject to expiration, so pay close attention to the expiration date of the card.<br/><br/>Your payment will be delivered via email with instructions for claiming your virtual Prepaid Mastercard. You must claim your card within 3 months from the date these instructions are sent via email. Your right to the payment expires after that time.<br/><br/></p>`,
    // offer
    offerButtonTxt: "Get Instant Offer",
    offerButtonTxtMany: "Get Instant Offer",
    offerBalanceInputPlaceHolder: "$ Remaining Balance",

    // card number and pin input boxes
    cardNumberPlaceHolderText: "Gift Card Number",
    cardPinPlaceHolderText: "PIN",

    // Have another card? Add it here?
    haveAnotherCardShowPopup: false,

    sellInstructionsDesktopTitle: "How It Works?",
    exchangeMastTextPartThreeV1: "Over 200 Brands Accepted!",

    cardCashUrl: "https://www.cardcash.com",
    tloResendCodeLinkTxt: "Resend Code",
    orderCompleteShowBonusTxt: false,
    agreementPageOn: true,
    agreementTitle: "About the Virtual Prepaid<br/>Mastercard",
    agreementContent: [
      "Digital card requires redemption within 90 days of issuance or it will expire.",
      "Once redeemed, the card will be valid for 12 months. ",
      "Redemption instructions will be provided via email once your order has been accepted.",
    ],
    agreementAcceptOfferTxt:
      "By clicking ‘Accept Offer’ I agree to exchange my gift cards for a Virtual Prepaid Mastercard",
    agreementContinueButtonTxt: "Accept Offer",
    stepsV3Flow: [
      ["/", "/sounds-good", "/agreement"],
      ["/card-details"],
      [
        "/identity",
        "/identity-card-info",
        "/identity-billing-info",
        "/identity-pass-code",
        "/identity-tlo",
      ],
      ["/order-complete"],
    ],
    theme: {
      background_color: "#F79E1B",
      hyperlink_color: "#FF5F00",
      primary_color: "#EB001B",
      secondary_color: "#EB001B",
      button_background_color: "#F79E1B",
      button_font_color: "#FFFFFF",
      sell_instructions_bg_color: "#404040",
      progress_bar: "rgba(222, 15, 65)",
    },
  },

  "test-mastercard": {
    googleAnalytics: "",
    x_cc_app: "nL73PBE0h",
    baseURL: "https://integration-api.cardcash.com/",
    cardCashUrl: "https://test.cardcash.com",
    reduxLogger: true,
  },

  localhost: {
    googleAnalytics: "",
    x_cc_app: "nL73PBE0h",
    baseURL: "https://integration-api.cardcash.com/",
    cardCashUrl: "https://test.cardcash.com",
    reduxLogger: true,
  },

  local: {
    googleAnalytics: "",
    x_cc_app: "nL73PBE0h",
    baseURL: "https://integration-api.cardcash.com/",
    cardCashUrl: "https://test.cardcash.com",
    reduxLogger: true,
  },
};

export const configModule = configBuilder("mastercard", Config);
